.part {
  display: flex;
  flex-wrap: wrap;
}

.half-width {
  flex: 1;
  width: 50%;
  margin: auto;
}

.slider {
  width: 60%;
  margin: auto;
  align-items: center;
}
