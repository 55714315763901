.table td {
  padding: 5px;
}

.table th {
  padding-bottom: 15px;
}

.table tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.no-results {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
