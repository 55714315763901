.menu {
  width: 100%;
  height: 10vh;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.2vw;
}

.search-box {
  background-color: #837c7c;
  height: 100%;
  border-width: 0;
  padding: 0;
  border-radius: 5px 0 0 5px;
  width: 20%;
}

.search-box::placeholder {
  color: #4a4545;
}

.search-icon {
  height: 50%;
}

.search-icon-container {
  background-color: #bab0b0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 0 5px 5px 0;
}

.sections {
  width: 100%;
  text-align: center;
  font-size: 0.8vw;
  font-weight: bold;
  font-style: italic;
  color: #837c7c;
  margin-top: 10px;
}

.sections a {
  padding: 10px;
  text-decoration: none;
  color: #837c7c;
}
