.sticker {
  padding: 1% 0 3%;
  border-radius: 10px;
  background-color: #e5432d;
  color: #fffdfc;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 80%;
  height: 80%;
  margin: auto;
}

.half-width {
  flex: 1;
}

.data {
  font-weight: normal;
  margin: 0 10%;
  border-radius: 10px;
  flex-basis: 100%;
  color: black;
  background-color: #fffdfc;
}

.title {
  font-size: 2.2em;
}

.part-number {
  font-size: 3vw;
  font-weight: bold;
  white-space: nowrap;
}

.honda-logo {
  max-width: 80%;
}
