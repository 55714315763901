.container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

body {
  background-color: #f9f8f9;
}
